import { useState } from 'react';

import type { FormField, FormSectionProps } from 'components/shared/organisms/form/FormSection/types';

const getFilteredFormFields = (
  allFields: FormField[],
  hiddenFields: Record<string, any | never>,
  validatedFields?: any,
) => {
  return allFields.reduce<FormField[]>(
    (result, { id, name, isDefaultVisible, dependentFields: _, parentFieldId, ...rest }) => {
      let isFieldVisible = isDefaultVisible || hiddenFields?.[name];

      if (!isFieldVisible && parentFieldId) {
        const { dependentFields: parentDependentFields } = allFields.find((item) => item.id === parentFieldId) || {};
        const parentHasRequiredValueToShowField = parentDependentFields?.some(
          (depField) =>
            depField.dependentFieldNames.includes(id.toString()) &&
            depField.parentOptionValue === validatedFields[parentFieldId],
        );
        isFieldVisible = !!parentHasRequiredValueToShowField;
      }

      if (isFieldVisible) {
        result.push({ id, name, ...rest });
      }
      return result;
    },
    [],
  );
};

const getHiddenFields = (dependentFieldNames: string[], value: any) => {
  return dependentFieldNames.reduce<Record<string, any>>((reduceResult, dependentFieldName) => {
    reduceResult[dependentFieldName] = value;
    return reduceResult;
  }, {});
};

const useFormHiddenFields = (
  formFields: FormField[],
  handleChange?: FormSectionProps['handleChange'],
  validatedFields?: any,
) => {
  const [hiddenFieldsStatus, setHiddenFieldsStatus] = useState({});

  const changeHandler: FormSectionProps['handleChange'] = (event) => {
    const { dependentFields } = formFields.find((field) => field.name === event.target.name) ?? {};

    if (dependentFields?.length) {
      dependentFields.forEach(({ dependentFieldNames, parentOptionValue }) => {
        setHiddenFieldsStatus((prevState) => ({
          ...prevState,
          ...getHiddenFields(dependentFieldNames, event.target.value === (parentOptionValue || 'true')),
        }));
      });
    }
    if (handleChange) handleChange(event);
  };

  const visibleFormFields = getFilteredFormFields(formFields, hiddenFieldsStatus, validatedFields);

  return { changeHandler, visibleFormFields };
};

export default useFormHiddenFields;
