import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DocumentGroup } from 'api/__generated__/enums';
import type { CreateRealEstateFormMutationVariables } from 'api/mutations/realEstate/useCreateRealEstateFormMutation';
import useCreateRealEstateFormMutation from 'api/mutations/realEstate/useCreateRealEstateFormMutation';
import useUpdateRealEstateFormMutation from 'api/mutations/realEstate/useUpdateRealEstateFormMutation';

import { ORIGIN, UUID } from 'config/constants/queryParams';

import useDocumentName from 'hooks/useDocumentName';
import useReferralId from 'hooks/useReferralId';

import FormSectionMediator from 'components/shared/molecules/FormSectionMediator';
import useFormHiddenSections from 'components/shared/molecules/FormSectionMediator/useFormHiddenSections';
import SubmitMessage from 'components/shared/organisms/form/SubmitMessage';
import type { UploadedFile } from 'components/ui/molecules/File/types';
import type { SubmitHandler } from 'components/ui/molecules/FormWithValidation';
import FormWithValidation from 'components/ui/molecules/FormWithValidation';

import getRealEstateFormReferralIdFromOrigin from '../getRealEstateFormReferralIdFromOrigin';
import { requiredValidationFields } from './config/form';
import { getFormSections } from './config/formSections';
import useRealEstateFormValues from './useRealEstateFormValues';

const RealEstateForm = () => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get(UUID);
  const origin = searchParams.get(ORIGIN);

  const { data: formValues, loading: formValuesLoading } = useRealEstateFormValues({ uuid });
  const getDocumentName = useDocumentName();

  const [uploadedDocuments, setUploadedDocuments] = useState<Record<string, UploadedFile>>({});
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const referralId = useReferralId(getRealEstateFormReferralIdFromOrigin);

  const [createRealEstateForm, { loading: createFormLoading }] = useCreateRealEstateFormMutation({
    onCompleted: () => {
      setIsSubmitted(true);
    },
  });

  const [updateRealEstateForm, { loading: updateFormLoading }] = useUpdateRealEstateFormMutation({
    onCompleted: () => {
      setIsSubmitted(true);
    },
  });

  const handleSubmit: SubmitHandler<CreateRealEstateFormMutationVariables> = async (newValues) => {
    const documentsAttributes = Object.values(uploadedDocuments).map(({ documentName, period, ...restData }) => {
      const group = (documentName as DocumentGroup) ?? DocumentGroup.Other;
      return {
        group,
        file: restData,
        name: getDocumentName(group, period),
        period,
      };
    });

    const variables = { ...newValues, documentsAttributes, userReferralId: referralId };

    if (uuid) {
      await updateRealEstateForm({
        variables: {
          uuid,
          ...variables,
        },
      });
    } else {
      await createRealEstateForm({
        variables: { applicationFormSource: origin || window.location.href, ...variables },
      });
    }
  };

  const formSections = getFormSections({ setUploadedDocuments, setIsDocumentsLoading });
  const { visibleFormSections, handleDependentFormSection } = useFormHiddenSections(formSections);

  if (isSubmitted) return <SubmitMessage />;

  return (
    <FormWithValidation
      id="real-estate-form"
      resetOnSubmit
      loading={formValuesLoading || createFormLoading || updateFormLoading}
      disabled={isDocumentsLoading || createFormLoading || updateFormLoading}
      defaultValues={formValues}
      requiredValidationFields={requiredValidationFields}
      submitHandler={handleSubmit}
    >
      {visibleFormSections.map(({ withHiddenFields, ...formSection }) => (
        <FormSectionMediator
          key={formSection.id}
          withHiddenFields={withHiddenFields || false}
          handleDependentFormSection={handleDependentFormSection}
          {...formSection}
        />
      ))}
    </FormWithValidation>
  );
};

export default RealEstateForm;
