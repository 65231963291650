import { DEFAULT_DATE_FORMAT } from 'config/constants/dateTimeFormats';

import type { FormField } from 'components/shared/organisms/form/FormSection/types';
import DateInput from 'components/ui/atoms/forms/DateInput';

export const AboutYourBusiness: FormField[] = [
  {
    id: 'businessStartedAt',
    name: 'businessStartedAt',
    label: 'When was your business started?',
    control: <DateInput inputFormat={DEFAULT_DATE_FORMAT} required disableFuture />,
    required: true,
  },
];
