import type { DocumentGroup } from 'api/__generated__/types';
import useTypeQueryHook from 'api/hooks/useTypeQueryHook';
import { DOCUMENT_GROUP_ENUM } from 'graphql/types/_enums';

import { formatTime } from 'lib/utils/date';

const useDocumentName = () => {
  const { data: documentGroups } = useTypeQueryHook({ name: DOCUMENT_GROUP_ENUM });

  return (documentGroupName: DocumentGroup, period?: string | undefined) => {
    const { description } = documentGroups?.find(({ name }) => name === documentGroupName) || {};
    if (!period || !['BANK_STATEMENT', 'PROFIT_AND_LOSS'].includes(documentGroupName)) return description;

    const format = documentGroupName === 'BANK_STATEMENT' ? 'MM/YYYY' : 'YYYY';
    return `${description} ${formatTime(period, format)}`;
  };
};

export default useDocumentName;
