import type { ManipulateType } from 'dayjs';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import { DEFAULT_DATE_FORMAT } from 'config/constants/dateTimeFormats';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export type IsoString = string;
type Format = string;

export const formatTime = (date: string | Date | dayjs.Dayjs, format: Format) => dayjs(date).format(format);

export const formattedDate = (date: string | Date | dayjs.Dayjs) =>
  date ? formatTime(date, DEFAULT_DATE_FORMAT) : null;

export const isDateValid = (date: string) => date !== 'Invalid Date';

export const formatToISOStringDateOnly = (isoString: IsoString) =>
  isoString ? dayjs(isoString).utc(true).format('YYYY-MM-DD') : isoString;

/**
 * Return first day of the month in the specified format.
 * @param {string | Date | dayjs.Dayjs} date - The date for which the first day of the month is to be determined.
 * @param {string} format - Date output format (by default "YYYY-MM-DD")
 * @returns {string} First day of the month in YYYY-MM-DD format.
 */
export const formatToFirstDayOfMonth = (date: string | Date | dayjs.Dayjs, format: string = 'YYYY-MM-DD'): string => {
  return dayjs(date).startOf('month').format(format);
};

/**
 * Adds the specified period to the date and returns it in 'YYYY-MM-DD' format.
 * @param {string | Date | dayjs.Dayjs} date - The date to be changed.
 * @param {number} amount - The number of units to add (can be negative).
 * @param {string} unit - Period type ('year', 'month', 'day', etc.).
 * @returns {string} Date with added period in 'YYYY-MM-DD' format.
 */
export const addToDate = (date: string | Date | dayjs.Dayjs, amount: number, unit: ManipulateType = 'day'): string => {
  return dayjs(date).add(amount, unit).format('YYYY-MM-DD');
};
