import { LoanType, Property } from 'api/__generated__/enums';
import { LOAN_TYPE, PROPERTY, STATE_ENUM } from 'graphql/types/_enums';

import type { FormField } from 'components/shared/organisms/form/FormSection/types';
import EnumSelect from 'components/ui/atoms/forms/EnumSelect';
import MultilineInput from 'components/ui/atoms/forms/MultilineInput';
import StringInput from 'components/ui/atoms/forms/StringInput';

import type { CreateRealEstateFormValues } from '../mutationsValues';

export const propertyInformationFields: FormField<CreateRealEstateFormValues>[] = [
  {
    id: 'propertyType',
    name: 'propertyType',
    label: 'Property Type',
    placeholder: 'Select Property Type',
    control: <EnumSelect enumName={PROPERTY} />,
    required: true,
    isDefaultVisible: true,
    dependentFields: [{ dependentFieldNames: ['propertyTypeDescription'], parentOptionValue: Property.Other }],
  },
  {
    id: 'propertyTypeDescription',
    name: 'propertyTypeDescription',
    label: 'Please describe the property type',
    // @ts-expect-error providing props through form
    control: <MultilineInput minRows={2} />,
    required: true,
    isDefaultVisible: false,
    parentFieldId: 'propertyType',
  },
  {
    id: 'loanType',
    name: 'loanType',
    label: 'Loan Type',
    placeholder: 'Select Loan Type',
    control: <EnumSelect enumName={LOAN_TYPE} />,
    required: true,
    isDefaultVisible: true,
    dependentSections: [
      { dependentSectionId: 'acquisitionInformation', parentOptionValue: LoanType.Acquisition },
      { dependentSectionId: 'refinanceInformation', parentOptionValue: LoanType.Refinance },
    ],
    dependentFields: [{ dependentFieldNames: ['loanTypeDescription'], parentOptionValue: LoanType.Other }],
  },
  {
    id: 'loanTypeDescription',
    name: 'loanTypeDescription',
    label: 'Please describe the loan type',
    // @ts-expect-error providing props through form
    control: <MultilineInput minRows={2} />,
    required: true,
    isDefaultVisible: false,
    parentFieldId: 'loanType',
  },
  {
    id: 'propertyAddress',
    name: 'propertyAddress',
    label: 'Property Address',
    placeholder: '123 Main St, Springfield',
    control: <StringInput />,
    required: false,
    isDefaultVisible: true,
  },
  {
    id: 'propertyState',
    name: 'propertyState',
    label: 'Property State',
    placeholder: 'Select Property State',
    control: <EnumSelect enumName={STATE_ENUM} />,
    required: false,
    isDefaultVisible: true,
  },
];
