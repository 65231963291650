import type { LoanType, Property } from 'api/__generated__/types';

import {
  YUP_NULLABLE_REQUIRED,
  YUP_NULLABLE_REQUIRED_VALID_EMAIL,
  YUP_REQUIRED_PHONE_NUMBER,
  YUP_STRING,
} from 'config/constants/yup';

import { getValidationRuleWhen } from 'lib/yup/methods/getValidationRuleWhen';

import type { CreateRealEstateFormValues } from './mutationsValues';

export const DEFAULT_VALUES = {
  merchantFirstName: '',
  merchantLastName: '',
  merchantEmail: null,
  merchantCellPhone: null,
  businessName: '',
  propertyType: '',
  propertyTypeDescription: '',
  loanType: '',
  loanTypeDescription: '',
  propertyAddress: '',
  propertyState: null,
};

type ValidationFields = {
  [Key in CreateRealEstateFormValues]: any;
};

export const requiredValidationFields: Pick<
  ValidationFields,
  | 'merchantFirstName'
  | 'merchantLastName'
  | 'merchantEmail'
  | 'merchantCellPhone'
  | 'propertyType'
  | 'propertyTypeDescription'
  | 'loanType'
  | 'loanTypeDescription'
> = {
  merchantFirstName: YUP_NULLABLE_REQUIRED,
  merchantLastName: YUP_NULLABLE_REQUIRED,
  merchantEmail: YUP_NULLABLE_REQUIRED_VALID_EMAIL,
  merchantCellPhone: YUP_REQUIRED_PHONE_NUMBER,
  propertyType: YUP_NULLABLE_REQUIRED,
  propertyTypeDescription: getValidationRuleWhen(YUP_STRING, ['propertyType'], {
    is: (value: Property) => value === 'OTHER',
    then: YUP_NULLABLE_REQUIRED,
  }),
  loanType: YUP_NULLABLE_REQUIRED,
  loanTypeDescription: getValidationRuleWhen(YUP_STRING, ['loanType'], {
    is: (value: LoanType) => value === 'OTHER',
    then: YUP_NULLABLE_REQUIRED,
  }),
};
