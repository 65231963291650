import { BUSINESS_INDUSTRY_ENUM } from 'graphql/types/_enums';

import { DEFAULT_DATE_FORMAT } from 'config/constants/dateTimeFormats';
import {
  YUP_DATE_REQUIRED_PAST,
  YUP_NULLABLE_REQUIRED,
  YUP_REQUIRED_SSN_LAST_4,
  YUP_VALID_ID,
} from 'config/constants/yup';

import DateInput from 'components/ui/atoms/forms/DateInput';
import EnumSelect from 'components/ui/atoms/forms/EnumSelect';
import IdentificationNumberInput from 'components/ui/atoms/forms/IdentificationNumberInput';
import SSNLast4 from 'components/ui/atoms/forms/SSNLast4';
import StringInput from 'components/ui/atoms/forms/StringInput';

export enum BusinessDetails {
  DateOfBirth = 'merchantDateOfBirth',
  Ssn = 'merchantSsn',
  AddressLine1 = 'merchantAddressLine1',
  BusinessAddressLine1 = 'businessAddressLine1',
  BusinessName = 'businessName',
  BusinessBusinessType = 'businessBusinessType',
  BusinessFederalTaxId = 'businessFederalTaxId',
}

export const fields = [
  {
    id: 'date-of-birth',
    name: BusinessDetails.DateOfBirth,
    label: 'Owner’s date of birth (mm/dd/yy)',
    control: <DateInput format={DEFAULT_DATE_FORMAT} disableFuture />,
    required: true,
  },
  {
    id: 'ssn',
    name: BusinessDetails.Ssn,
    control: <SSNLast4 />,
    label: 'Last 4 digits of owner’s SSN',
    required: true,
  },
  {
    id: 'business-address-line1',
    name: BusinessDetails.BusinessAddressLine1,
    control: <StringInput />,
    placeholder: 'Enter business address',
    label: 'Business address (no apartment, suite or P.O. Box)',
    required: true,
  },
  {
    id: 'address-line1',
    name: BusinessDetails.AddressLine1,
    control: <StringInput />,
    placeholder: 'Enter home address',
    label: 'Home address (no apartment, suite or P.O. Box)',
    required: true,
  },
  {
    id: 'business-name',
    name: BusinessDetails.BusinessName,
    control: <StringInput />,
    placeholder: 'Enter business name',
    label: 'Legal business name or DBA',
    required: true,
  },
  {
    id: 'business-business-type',
    name: BusinessDetails.BusinessBusinessType,
    control: <EnumSelect enumName={BUSINESS_INDUSTRY_ENUM} label="Type to search" />,
    placeholder: 'Type to search',
    label: 'Industry',
    required: true,
  },
  {
    id: 'business-federal-tax-id',
    name: BusinessDetails.BusinessFederalTaxId,
    control: <IdentificationNumberInput />,
    placeholder: 'Enter EIN',
    label: 'Employer Identification Number (EIN)',
    required: true,
  },
];

export const DEFAULT_VALUES = {
  [BusinessDetails.DateOfBirth]: null,
  [BusinessDetails.Ssn]: '',
  [BusinessDetails.BusinessAddressLine1]: '',
  [BusinessDetails.AddressLine1]: '',
  [BusinessDetails.BusinessName]: '',
  [BusinessDetails.BusinessBusinessType]: '',
  [BusinessDetails.BusinessFederalTaxId]: '',
};

export const requiredValidationFields = {
  [BusinessDetails.DateOfBirth]: YUP_DATE_REQUIRED_PAST,
  [BusinessDetails.Ssn]: YUP_REQUIRED_SSN_LAST_4,
  [BusinessDetails.BusinessAddressLine1]: YUP_NULLABLE_REQUIRED,
  [BusinessDetails.AddressLine1]: YUP_NULLABLE_REQUIRED,
  [BusinessDetails.BusinessName]: YUP_NULLABLE_REQUIRED,
  [BusinessDetails.BusinessBusinessType]: YUP_NULLABLE_REQUIRED,
  [BusinessDetails.BusinessFederalTaxId]: YUP_VALID_ID,
};
