import { useMemo, useState } from 'react';
import omit from 'lodash/omit';

import useUpload from 'hooks/useUpload';

import noop from 'lib/utils/noop';

const getFileKey = (name, size) => `${name}_${size}`;

const useFileUploader = ({
  setUploaded,
  sizeLimitMb,
  multiple = false,
  onFilesChange = noop,
  documentName = '',
  period = '',
}) => {
  const [localFiles, setLocalFiles] = useState({});

  const { upload } = useUpload({ sizeLimitMb });

  const showDropZone = useMemo(
    () => multiple || Object.values(localFiles).filter((value) => value).length === 0,
    [multiple, localFiles],
  );

  const setUploads = (uploaded) => {
    if (!uploaded) return;

    const additionalFields = {
      ...(documentName ? { documentName } : undefined),
      ...(period ? { period } : undefined),
    };
    const resUploaded = { ...uploaded, ...additionalFields };

    if (!multiple) {
      setUploaded(resUploaded);
      return;
    }

    const { metadata } = uploaded || {};
    const { filename, size } = metadata || {};
    setUploaded((existing) => ({ ...existing, [getFileKey(filename, size)]: resUploaded }));
  };

  const uploadFiles = async (files) => {
    setLocalFiles((existing) => ({
      ...existing,
      ...files.reduce(
        (result, file, index) => ({
          ...result,
          [getFileKey(file.name, file.size)]: { file, order: Object.keys(existing).length + index + 1 },
        }),
        {},
      ),
    }));
    onFilesChange();
  };

  const handleCancelFile = (key) => {
    setLocalFiles((existing) => omit(existing, [key]));
    setUploaded(multiple ? (existing) => omit(existing, [key]) : null);
    onFilesChange();
  };

  return {
    localFiles,
    setLocalFiles,
    upload,
    showDropZone,
    setUploads,
    uploadFiles,
    handleCancelFile,
  };
};

export default useFileUploader;
