import type { DocumentGroup } from 'api/__generated__/types';

import { addToDate, formatToFirstDayOfMonth } from 'lib/utils/date';

import type { DocumentsUploadFormProps } from 'components/shared/organisms/form/DocumentsUploadForm';
import DocumentsUploadForm from 'components/shared/organisms/form/DocumentsUploadForm';
import type { FormField } from 'components/shared/organisms/form/FormSection/types';
import MoneyInput from 'components/ui/atoms/forms/MoneyInput';
import MultilineInput from 'components/ui/atoms/forms/MultilineInput';

import type { CreateRealEstateFormValues } from '../mutationsValues';

export type GetDocumentsFieldsArgs = Pick<DocumentsUploadFormProps, 'setUploadedDocuments' | 'setIsDocumentsLoading'>;

type GetDocumentsFields = ({
  setUploadedDocuments,
  setIsDocumentsLoading,
}: GetDocumentsFieldsArgs) => FormField<DocumentGroup | CreateRealEstateFormValues>[];

export const getDocumentsFields: GetDocumentsFields = ({ setUploadedDocuments, setIsDocumentsLoading }) => [
  {
    id: 'rentRoll',
    name: 'CURRENT_RENT_ROLL',
    control: (
      <DocumentsUploadForm setUploadedDocuments={setUploadedDocuments} setIsDocumentsLoading={setIsDocumentsLoading} />
    ),
    fieldTitle: 'Current Rent Roll',
  },
  {
    id: 'yearToDateP&L',
    name: 'PROFIT_AND_LOSS',
    control: (
      <DocumentsUploadForm
        setUploadedDocuments={setUploadedDocuments}
        setIsDocumentsLoading={setIsDocumentsLoading}
        period={formatToFirstDayOfMonth(new Date())}
      />
    ),
    fieldTitle: 'Year-to-date Profit and Loss statements',
  },
  {
    id: 'lastYearP&L',
    name: 'PROFIT_AND_LOSS',
    control: (
      <DocumentsUploadForm
        setUploadedDocuments={setUploadedDocuments}
        setIsDocumentsLoading={setIsDocumentsLoading}
        period={formatToFirstDayOfMonth(addToDate(new Date(), -1, 'year'))}
      />
    ),
    fieldTitle: 'Last year’s Profit and Loss statements',
  },
  {
    id: 'offeringMemorandum',
    name: 'OFFERING_MEMORANDUM',
    control: (
      <DocumentsUploadForm
        label="This is a PDF that the sales broker puts together to market the property."
        setUploadedDocuments={setUploadedDocuments}
        setIsDocumentsLoading={setIsDocumentsLoading}
      />
    ),
    fieldTitle: 'Offering Memorandum',
  },
  {
    id: 'personalFinancialStatement',
    name: 'BANK_STATEMENT',
    control: (
      <DocumentsUploadForm
        label="If this cannot be provided by the client, please provide a detailed report of how many properties the client owns, and an estimated net worth and liquidity statements."
        setUploadedDocuments={setUploadedDocuments}
        setIsDocumentsLoading={setIsDocumentsLoading}
        period={formatToFirstDayOfMonth(new Date())}
      />
    ),
    fieldTitle: 'Personal Financial Statement',
  },
  {
    id: 'liquidity',
    name: 'liquidity',
    label: 'Please describe',
    // @ts-expect-error providing props through form
    control: <MultilineInput minRows={2} />,
    fieldTitle: 'Liquidity',
  },
  {
    id: 'otherProperties',
    name: 'propertiesAmountAndEstimatedEvaluations',
    label: 'Please detail how many properties client owns, and their estimated evaluations',
    // @ts-expect-error providing props through form
    control: <MultilineInput minRows={2} />,
    fieldTitle: 'Other Real-Estate Properties',
  },
  {
    id: 'netWorth',
    name: 'netWorth',
    label: 'Net worth ($)',
    control: <MoneyInput />,
    columns: 2,
  },
];
